import styles from './Contact.module.css';
import Localizer from '../common/localizer';
import Content from './Content';
import { useEffect } from 'react';


function Contact() {

    const FB = window.FB

    useEffect(() => {
        if (FB) {
            FB.XFBML.parse();
        }
    })

    return (
        <>
        <h1 className={'pageHeader ' + styles.contactUsHeader}>{Localizer.localize('contactHeader')}</h1>
            <div id="fb-root"></div>
            <Content>
                {/* <div className={styles.contactContainer}> */}
                <div>
                    <h1>{Localizer.localize('socialmedia')}</h1>
                    <div class="fb-page" data-href="https://www.facebook.com/PlumbingByAJ" data-tabs="" data-width="800" data-height="400" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false">
                        <blockquote cite="https://www.facebook.com/PlumbingByAJ" class="fb-xfbml-parse-ignore">
                            <a href="https://www.facebook.com/PlumbingByAJ">
                                AJ Plumbing LLC
                            </a>
                        </blockquote>
                    </div>
                </div>

                <div>
                    <h1>{Localizer.localize('email')}</h1>
                    <a className={styles.contactAnchor} href='mailto:alex.ajplumbingllc@gmail.com'>alex.ajplumbingllc@gmail.com</a>
                    <br/>
                    or                    
                    <br/>
                    <a className={styles.contactAnchor} href='mailto:ajplumbingllc@hotmail.com'>ajplumbingllc@hotmail.com</a>
                </div>

                <div>
                    <h1>{Localizer.localize('phone')}</h1>
                    {Localizer.localize('cell')}: <a className={styles.contactAnchor} href='tel:2562001319'>256-200-1319</a>
                    <br />
                    {Localizer.localize('office')}: <a className={styles.contactAnchor} href='tel:2567017317'>256-701-7317</a>
                </div>

                {/* <div>
                    <h1>Address</h1>
                    1234 Athens St
                    <br />
                    Athens, AL 35611
                </div> */}
            </Content>
        </>
    )
}

export default Contact
import Localizer from "../common/localizer"
import Content from './Content';
import styles from './Area.module.css'

function Area() {

    let areasOff = [
        'Madison'
        , 'Athens'
        , 'Huntsville'
        , 'Toney'
        , 'Harvest'
        , 'Meridianville'
        , 'Decatur'
        , 'HazelGreen'
        , 'Elkmont'
    ]

    let localizedAreas = areasOff.map(area => Localizer.localize(area));

    return (
        <>
            <h1 className={'pageHeader ' + styles.areaHeader}> {Localizer.localize('areaintro')} </h1>
            <Content>
                <div className={styles.areasServed}>
                    <ul className={styles.areas}>
                        {localizedAreas.map(localizedArea => <><li className={styles.area}>{localizedArea}</li></>)}

                    </ul>
                </div>
            </Content>
        </>
    )
}

export default Area

const strings = {
    // * general
    'welcome': 'Bienvenidos a AJ Plumbing LLC'


    // * home 
    , 'homeHeader': 'Con Licencia y Seguro'
    , 'homeintro': 'AJ Plumbing se esfuerza por ofrecer reparaciones y servicios de la más alta calidad que duren y cumplan con las expectativas de nuestros clientes. Nuestro objetivo es mantener altos estándares de honestidad y servicio al cliente confiable. Queremos completar cada trabajo lo más rápido posible y al mismo tiempo hacerlo conveniente para nuestros clientes. Nos especializamos en servicios de plomería generales y de emergencia para el norte de Alabama.'


    // * gallery
    , 'galleryHeader': 'Eche un vistazo a algunos de nuestros proyectos anteriores!'

    , 'gallery': 'Galería'
    , 'loading': 'Cargando'
    , 'crawlspace': 'Entresuelo'
    , 'underground': 'Subterráneo'
    , 'inside': 'Adentro'
    , 'transformations': 'Transformaciones'

    // * services
    , 'services': 'Servicios'

    , 'servicesintro': 'Plomería Libre de Preocupación!'
    //proposed catagories
    // Repair
    // Maintenence
    // Installation
    // location type

    // actual catagories
    , 'buildingTypeHeader': 'En Todas Partes'
    , 'projectTypeHeader': 'Cualquier Etapa del Proyecto'
    , 'servicesOfferedHeader': 'Lo Tenemos Cubierto'

    , 'multiunitrealestate': 'Unidades Multiples'
    , 'Newconstruction': 'Nueva Construccion'
    , 'Remodeling': 'Remodelacion'
    , 'ResidentialService': 'Servicio Residencial'
    , 'Commercialservice': 'Servicio comercial'
    , 'Faucetinstallation': 'Instalacion de Llave o Grifo'
    , 'Showerinstallation': 'Instalación de ducha '
    , 'Toiletinstallation': 'Instalacion de inodoros'
    , 'Waterheaterinstallation': 'Instalación del calentador de agua'
    , 'Faucetrepair': 'Reparación de Llave o Grifos'
    , 'Plumbingpiperepair': 'Reparación de tuberías de plomería'
    , 'Toiletrepair': 'Reparacion de banos'
    , 'Garbagedisposalinstallation': 'Instalacion de Basurero'
    , 'Plumbingleakrepair': 'Reparación de fugas de plomería'
    , 'Garbagedisposalrepair': 'Reparacion de Basurero'
    , 'Outdoorplumbingsystem repair': 'Reparación del sistema de plomería exterior'
    , 'Sewerrepair': 'Reparación de alcantarillado'
    , 'Showerrepair': 'Reparacion de ducha'
    , 'Waterheaterrepair': 'Reparacion de calentador de agua'
    , 'Draincleaning': 'Limpieza de desagües'
    // Services -> other
    , 'roughplumbing': 'plomería áspera'
    , 'roughplumbingdetails': 'detalles...'
    , 'bathroomremodels': 'remodelaciones de baños'
    , 'bathroomremodelsdetails': 'detalles...'

    // * Area
    , 'area': 'Área de Servicio'

    , 'areaintro': '¿Está en nuestra área de servicio?'

    // Areas served.
    , 'Madison': 'Madison'
    , 'Athens': 'Athens'
    , 'Huntsville': 'Huntsville'
    , 'Toney': 'Toney'
    , 'Harvest': 'Harvest'
    , 'Meridianville': 'Meridianville'
    , 'Decatur': 'Decatur'
    , 'HazelGreen': 'Hazel Green'
    , 'Elkmont': 'Elkmont'

    // * Reviews
    , 'reviews': 'Reviews'
    , 'reviewsintro': 'Don\'t take it from us!'

    // * Contact
    , 'contactHeader': `¡No sea tímido, contáctenos hoy!`

    , 'contact': 'Contactar'

    , 'contactus': 'Contáctenos'

    , 'socialmedia': 'Medios de Comunicación Social'
    , 'phone': 'Teléfono'
    , 'cell': 'Celular'
    , 'email': 'Correo Electrónico'
    , 'office': 'Oficina'

}
export default strings
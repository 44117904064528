import en_strings from '../assets/strings/en.js'
import es_strings from '../assets/strings/es.js'

class Localizer {
    static language
    static strings
    static isSupported(language) {
        return language === 'en' || language === 'es'
    }
    static getBaseLanguage(language) {
        if(!language || language.length < 2)
        {return ''}
        return language.substring(0,2).toLowerCase()
    }
    static loadStrings(language) {
        if(language === 'es') {
            this.strings = es_strings
            return
        }
        this.strings = en_strings
    }
    static localize(string) {
        if(!string || !this.strings || !(string in this.strings)) {
            return ''
        }
        return this.strings[string]
    }
    static {
        this.language = this.getBaseLanguage(window.navigator.userLanguage || window.navigator.language || window.navigator.languages[0])
        let i = 1
        while (!this.isSupported(this.language) && i < window.navigator.languages.length){
            this.language = this.getBaseLanguage(window.navigator.languages[i])
        }
        this.loadStrings(this.language)
    }
}

export default Localizer;
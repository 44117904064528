import Localizer from "../common/localizer"
import Content from './Content';
import styles from './Reviews.module.css'

function Reviews() {
    return (<>
        <h1 className={'pageHeader ' + styles.reviewHeader}> {Localizer.localize('reviewsintro')} </h1>
        <Content>

        </Content>
    </>
    )
}

export default Reviews
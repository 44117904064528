import styles from './Home.module.css';
import Localizer from '../common/localizer';
import Content from './Content';

function Home() {
    return (
        <>
            <h1 className={'pageHeader ' + styles.homeHeader}>{Localizer.localize('homeHeader')}</h1>
            <div className={styles.homeContainer} >
                <Content>
                    <h1>{Localizer.localize('homeintro')}</h1>
                    <img src='/ajAndTruck_web.png' alt='unable to load' style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </Content>
            </div>
        </>
    )
}

export default Home;
import { useEffect } from 'react'
import { useCallback } from 'react'
import { useState } from 'react'
import styles from './Hamburger.module.css'


export default function Hamburger(props) {

    const [active, setActive] = useState(closedToActive(props.closed))
    const cached = useCallback(closedToActive, [])
    useEffect(() => setActive(cached(props.closed)), [props.closed, cached])

    function handleHamburgerClick() {
        setActive(closedToActive(active === styles.active))
        if (props.onClick instanceof Function) {
            props.onClick()
        }
    }

    function closedToActive(closed) {
        return closed ? '' : styles.active
    }

    return (
        <>
            <div className={`${styles.hamburgerBox} ${props.className} ${active}`} >
                <button className={`${styles.hamburger} ${props.className} ${active}`} onClick={handleHamburgerClick}>
                    <div className={styles.pattyBox}>
                        <span id={styles.patty1} className={`${styles.hamburgerPatty} ${props.className} ${active}`} />
                        <span id={styles.patty2} className={`${styles.hamburgerPatty} ${props.className} ${active}`} />
                        <span id={styles.patty3} className={`${styles.hamburgerPatty} ${props.className} ${active}`} />
                    </div>
                </button>
            </div>
        </>
    )
}
import styles from './OverlayButton.module.css'


export default function OverlayButton(props) {

    function clickOverlay(event) {
        if(props.onClick instanceof Function) {
            props.onClick()
        }
    }

    return (
        <>
            <button className={`${styles.z_indexed_overlay_clickoff} ${props.className}`} onClick={clickOverlay} />
        </>
    )
}
import { useState, useEffect, useRef } from "react";

function useLazyLoad() {

    const [invisible, invisibleChanged] = useState(true)
    const parentRef = useRef(null)

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                invisibleChanged(entry.isIntersecting)
            })
        }, {
            rootMargin: "0%",
            threshold: 0,
        })

        if (parentRef.current) {
            intersectionObserver.observe(parentRef.current)
        }

        let parentRefCurr = parentRef.current

        return () => {
            if (parentRefCurr) {
                intersectionObserver.unobserve(parentRefCurr)
            }
        }
    },
        [parentRef, invisible])



    return [parentRef, invisible]
}

export default useLazyLoad
import styles from './Gallery.module.css';
import { Component } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initializeApp } from 'firebase/app'
import Loading from './Loading';
import OverlayButton from './OverlayButton'
import Localizer from '../common/localizer';
// import Transformation from './Transformation';

// https://firebase.google.com/docs/web/setup#available-libraries
// import { getAnalytics } from 'firebase/analytics'
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'

function withRouter(Child) {
    return (props) => {
        const params = useSearchParams();
        return <Child {...props} params={params} />;
    }
}

class Gallery extends Component {

    constructor(params) {
        super(params)
        this.state = {
            overlayActive: false,
            loading: true,
            x: 0,
            transition: false,
            photo: false
        }
        this.focusMedia = this.focusMedia.bind(this)
        this.focusTransformation = this.focusTransformation.bind(this)
        this.handleFocusedTransformationResize = this.handleFocusedTransformationResize.bind(this)
        this.onReceive = this.onReceive.bind(this)
        this.onReceiveTransformations = this.onReceiveTransformations.bind(this)
        this.updateQuery = this.updateQuery.bind(this)
        this.getQuery = this.getQuery.bind(this)
        this.getQueryFromParams = this.getQueryFromParams.bind(this)
        this.unFocusMedia = this.unFocusMedia.bind(this)
        this.revealTransformation = this.revealTransformation.bind(this)
        this.revealTransformationTouch = this.revealTransformationTouch.bind(this)
        this.preventDefault = this.preventDefault.bind(this)
        this.revealTransformationStart = this.revealTransformationStart.bind(this)
        this.revealTransformationEnd = this.revealTransformationEnd.bind(this)
        this.calculateTransformationDimensions = this.calculateTransformationDimensions.bind(this)
        this.convertTransformationCoordinates = this.convertTransformationCoordinates.bind(this)
        this.enableScroll = this.enableScroll.bind(this)
        this.disableScroll = this.disableScroll.bind(this)
    }

    images = []

    videos = []

    transformations = []

    mediaObject

    transformationObject

    cloudCollectionName = 'Gallery'

    revealTransformationStart(event) {
        document.addEventListener('mousemove', this.revealTransformation) // Do these play nice with mobile?
        document.addEventListener('mouseup', this.revealTransformationEnd)
        document.addEventListener('touchmove', this.revealTransformationTouch)
        document.addEventListener('touchend', this.revealTransformationEnd)
    }

    preventDefault(event) {
        event.preventDefault()
    }

    revealTransformation(event) {
        if (event.clientX !== 0) {
            let newx = this.convertTransformationCoordinates(event.clientX, this.transformationObject.width)
            this.setState({ ...this.state, x: newx })
        }
    }

    revealTransformationTouch(event) {
        if (event.touches.length > 0 && event.touches[0].clientX !== 0) {
            let newx = this.convertTransformationCoordinates(event.touches[0].clientX, this.transformationObject.width)
            this.setState({ ...this.state, x: newx })
        }
    }

    convertTransformationCoordinates(x, photoWidth) {
        let vpSize = window.innerWidth // This will need to be updated on resize
        let subtract = (vpSize / 2) - (photoWidth / 2)
        let converted = x - subtract
        let twoPoint5VW = Math.round(document.documentElement.clientWidth * 0.025)
        // bound by frame
        return Math.max(0 - twoPoint5VW, Math.min(photoWidth - twoPoint5VW, converted))
    }

    revealTransformationEnd(event) {
        document.removeEventListener('mouseup', this.revealTransformationEnd)
        document.removeEventListener('mousemove', this.revealTransformation)
        document.removeEventListener('touchmove', this.revealTransformationTouch)
        document.removeEventListener('touchend', this.revealTransformationEnd)
    }

    onReceive(galleryDocs) {
        const fetchedDocs = galleryDocs.docs.map(doc => doc.data())
        const storage = getStorage(this.app)
        const gallerRefs = fetchedDocs.map(fetchedDoc => ref(storage, `${fetchedDoc.path}/${fetchedDoc.name}`))
        const imageUrlResolutions = gallerRefs.filter((ref) => ref.fullPath.includes('images')).map(ref => getDownloadURL(ref).then(url => url))
        const videoUrlResolutions = gallerRefs.filter((ref) => ref.fullPath.includes('videos')).map(ref => getDownloadURL(ref).then(url => url))
        const imageResolutionLump = Promise.all(imageUrlResolutions).then(values => this.images = values)
        const videoResolutionLump = Promise.all(videoUrlResolutions).then(values => this.videos = values)
        Promise.all([imageResolutionLump, videoResolutionLump]).then((values) =>
            this.setState({ ...this.state, loading: false })
        )
    }

    onReceiveTransformations(galleryDocs) {
        const fetchedDocs = galleryDocs.docs.map(doc => doc.data())
        const storage = getStorage(this.app)
        const gallerRefs = fetchedDocs.map((fetchedDoc) => Promise.all([getDownloadURL(ref(storage, `${fetchedDoc.path}/${fetchedDoc.before_name}`)), getDownloadURL(ref(storage, `${fetchedDoc.path}/${fetchedDoc.after_name}`))]).then((values) => ({ before: values[0], after: values[1] })))
        Promise.all(gallerRefs).then((values) => {
            this.transformations = values
            this.setState({ ...this.state, loading: false })
        })
    }

    disableScroll() {
        document.body.style.overflow = 'hidden'
        document.addEventListener('touchmove', this.preventDefault, true)
        document.addEventListener('scroll', this.preventDefault, true)
    }

    enableScroll() {
        document.body.style.overflow = 'auto'
        document.removeEventListener('touchmove', this.preventDefault, true)
        document.removeEventListener('scroll', this.preventDefault, true)
    }

    focusMedia(event) {
        let mediaElement = event.target
        if (mediaElement == null || mediaElement.src == null) {
            return
        }
        let newMediaObject = <></>
        if (mediaElement.nodeName === 'VIDEO') {
            newMediaObject = <video src={mediaElement.src} className={styles.focusedMedia} autoPlay controls alt='generic' />
        }
        if (mediaElement.nodeName === 'IMG') {
            newMediaObject = <img src={mediaElement.src} className={styles.focusedMedia} alt='generic' />
        }
        if (newMediaObject == null) {
            return
        }
        this.disableScroll()
        this.mediaObject = newMediaObject
        this.setState({ ...this.state, overlayActive: true })
    }

    calculateTransformationDimensions() {
        let maxWidth = 0.9 * window.innerWidth
        let maxHeight = 0.9 * window.innerHeight

        let aspect = this.transformationObject.naturalWidth / this.transformationObject.naturalHeight
        let widthLimiting = this.transformationObject.naturalWidth > maxWidth
        let heightLimiting = this.transformationObject.naturalWidth > maxHeight
        let toWidth = this.transformationObject.naturalWidth
        let toHeight = this.transformationObject.naturalHeight
        if (widthLimiting && heightLimiting) {
            if ((maxWidth / toWidth) > (maxHeight / toHeight)) {
                toHeight = maxHeight
                toWidth = toHeight * aspect
            }
            else {
                toWidth = maxWidth
                toHeight = toWidth / aspect
            }
        }
        else if (widthLimiting) {
            toWidth = maxWidth
            toHeight = toWidth / aspect
        }
        else if (heightLimiting) {
            toHeight = maxHeight
            toWidth = toHeight * aspect
        }

        this.transformationObject.width = Math.round(toWidth)
        this.transformationObject.height = Math.round(toHeight)
    }

    focusTransformation(event) {
        let mediaElement = event.target
        if (mediaElement == null || mediaElement.src == null || mediaElement.nodeName !== 'IMG') {
            return
        }
        this.disableScroll()

        this.transformationObject = {
            before: mediaElement.src,
            after: mediaElement.parentNode.lastChild.src,
            naturalWidth: mediaElement.naturalWidth,
            naturalHeight: mediaElement.naturalHeight
        }

        this.calculateTransformationDimensions()
        window.addEventListener('resize', this.handleFocusedTransformationResize)
        this.setState({ ...this.state, overlayActive: true, x: (this.transformationObject.width - (document.documentElement.clientWidth * 0.05)) / 2 })
    }

    handleFocusedTransformationResize() {
        this.calculateTransformationDimensions()
        this.setState({ ...this.state, x: (this.transformationObject.width - (document.documentElement.clientWidth * 0.05)) / 2 })
    }

    unFocusMedia(event) {
        window.removeEventListener('resize', this.handleFocusedTransformationResize)
        this.mediaObject = null
        this.transformationObject = null
        this.enableScroll()
        this.setState({ ...this.state, overlayActive: false })
    }


    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    firebaseConfig = {
        apiKey: "AIzaSyDysfLbKjOg5JvTKpm_qE6r7Ggh3VkUhvA",
        authDomain: "aj-plumbing-45a5a.firebaseapp.com",
        projectId: "aj-plumbing-45a5a",
        storageBucket: "aj-plumbing-45a5a.appspot.com",
        messagingSenderId: "40201008925",
        appId: "1:40201008925:web:b4c5f0b3f73cc5ca0493f4",
        measurementId: "G-23HCZC1629"
    };

    // Initialize Firebase
    app = initializeApp(this.firebaseConfig)

    componentDidMount() {
        const urlQuery = this.getQueryFromParams(this.props.params)
        this.updateQuery(urlQuery)
    }

    getQueryFromParams(params) {
        const query = params[0].get('query')
        try {
            return JSON.parse(query)
        }
        catch {
            return null
        }
    }

    getQuery(galleryCollection, urlQuery) {
        const constraints = []
        if (typeof urlQuery?.path === 'string' && urlQuery.path.length > 0) {
            constraints.push(where('path', '==', urlQuery.path))
        }
        if (typeof urlQuery?.job_location === 'string' && urlQuery.job_location.length > 0) {
            constraints.push(where('job_location', '==', urlQuery.job_location))
        }
        return query(galleryCollection, ...constraints)
    }

    updateQuery(urlQuery) {
        const db = getFirestore(this.app)
        let galleryCollection
        if (typeof urlQuery?.collection === 'string' && urlQuery.collection.length > 0) {
            galleryCollection = collection(db, urlQuery.collection)
            const galleryQuery = this.getQuery(galleryCollection, urlQuery)
            getDocs(galleryQuery).then(this.onReceiveTransformations)
        }
        else {
            galleryCollection = collection(db, this.cloudCollectionName)
            const galleryQuery = this.getQuery(galleryCollection, urlQuery)
            getDocs(galleryQuery).then(this.onReceive)
        }
    }

    componentDidUpdate(prevParams, prevState, snapshot) {
        const newUrlQuery = this.getQueryFromParams(this.props.params)
        const oldUrlQuery = this.getQueryFromParams(prevParams.params)
        if (newUrlQuery === oldUrlQuery ||
            (newUrlQuery?.path === oldUrlQuery?.path
                &&
                newUrlQuery?.job_location === oldUrlQuery?.job_location
                &&
                newUrlQuery?.collection === oldUrlQuery?.collection
            )
        ) {
            return
        }
        this.images = []
        this.videos = []
        this.transformations = []
        this.setState({ ...this.state, loading: true })
        this.updateQuery(newUrlQuery)
    }

    render() {
        return (
            <>
                <h1 className={'pageHeader ' + styles.galleryHeader}>{Localizer.localize('galleryHeader')}</h1>
                {(this.state.overlayActive) &&
                    <div className={`${styles.overlay}`}>
                        {/* <button className={styles.z_indexed_overlay_clickoff} onClick={this.unFocusMedia} /> */}
                        <OverlayButton className={`${styles.z_indexed_overlay_clickoff}`} onClick={this.unFocusMedia} />
                        <button className={styles.x} onClick={this.unFocusMedia}>
                            <span className={styles.crossMember1} />
                            <span className={styles.crossMember2} />
                        </button>
                        {this.mediaObject}
                        {(this.transformationObject) &&
                            <div style={{ backgroundImage: `URL("${this.transformationObject.after}")`, width: `${this.transformationObject.width}px`, height: `${this.transformationObject.height}px`, overflow: "hidden", backgroundSize: 'cover' }} className={styles.focusedTransformationContainer + ' ' + styles.focusedMedia}>
                                <div style={{ left: `${this.state.x}px` }} className={styles.slide}>
                                    <span onTouchStart={this.revealTransformationStart} onMouseDown={this.revealTransformationStart} className={styles.transformationDivider} />
                                </div>
                                <img src={this.transformationObject.before} className={styles.focusedBefore} style={{ clipPath: `inset(0 0 0 ${this.state.x + Math.round(document.documentElement.clientWidth * 0.025)}px)` }} alt='generic' />
                            </div>
                        }
                    </div>
                }
                <div className={styles.galleryContainer}>
                    {this.images.map(image => <img src={image} key={image.toString()} onClick={this.focusMedia} className={`${styles.galleryImages} ${styles.galleryItem}`} alt='generic' />)}
                    {this.videos.map(vid => <video src={vid} key={vid.toString()} onClick={this.focusMedia} className={`${styles.galleryVideos} ${styles.galleryItem}`} autoPlay loop muted />)}
                    {/* <Transformation className={styles.galleryItem} before={this.images[0]} after={this.images[1]} onClick={this.focusMedia}></Transformation> */}
                    {this.transformations.map((transformation) => <div className={styles.galleryItem + ' ' + styles.transformationContainer} onClick={this.focusTransformation}>
                        <img src={transformation.before} className={styles.before} alt='generic' />
                        <img src={transformation.after} className={styles.after} alt='generic' />
                    </div>)}

                </div>
                {this.state.loading && <Loading />}
            </>
        )
    }
}

export default withRouter(Gallery)
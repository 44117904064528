import Localizer from "../common/localizer"
import Content from './Content';
import styles from './Services.module.css'

function Services() {

    let projectType = [
        'Newconstruction'
        , 'Remodeling'
    ]

    let buildingType = [
        'ResidentialService'
        , 'Commercialservice'
        , 'multiunitrealestate'
    ]

    let servicesOff = [
        'Faucetinstallation'
        , 'Faucetrepair'
        , 'Showerinstallation'
        , 'Showerrepair'
        , 'Toiletinstallation'
        , 'Toiletrepair'
        , 'Sewerrepair'
        , 'Draincleaning'
        , 'Waterheaterinstallation'
        , 'Waterheaterrepair'
        , 'Plumbingpiperepair'
        , 'Plumbingleakrepair'
        , 'Garbagedisposalinstallation'
        , 'Garbagedisposalrepair'
        , 'Outdoorplumbingsystem repair'
    ]

    let localizedServices = servicesOff.map(service => Localizer.localize(service));
    let localizedBuildingType = buildingType.map(type => Localizer.localize(type));
    let localizedProjectType = projectType.map(type => Localizer.localize(type));

    return (
        <>
            <h1 className={'pageHeader ' + styles.servicesHeader}> {Localizer.localize('servicesintro')} </h1>
            <Content>
                <div className={styles.servicesOffered}>
                    <h1 className={styles.serviceHeading}>{Localizer.localize('buildingTypeHeader')}</h1>
                    <ul className={styles.services}>
                        {localizedBuildingType.map(localizedService => <><li className={styles.service}>{localizedService}</li></>)}
                    </ul>
                </div>
                <div className={styles.servicesOffered}>
                    <h1 className={styles.serviceHeading}>{Localizer.localize('projectTypeHeader')}</h1>
                    <ul className={styles.services}>
                        {localizedProjectType.map(localizedService => <><li className={styles.service}>{localizedService}</li></>)}
                    </ul>
                </div>
                <div className={styles.servicesOffered}>
                    <h1 className={styles.serviceHeading}>{Localizer.localize('servicesOfferedHeader')}</h1>
                    <ul className={styles.services}>
                        {localizedServices.map(localizedService => <><li className={styles.service}>{localizedService}</li></>)}
                    </ul>
                </div>
            </Content>
        </>
    )
}

export default Services
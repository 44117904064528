import styles from './Content.module.css';
import ContentSection from './ContentSection.jsx';

function Content(props) {

    let children = []
    if (!props?.children) {

    }
    else if (!Array.isArray(props.children)) {
        children.push(props.children)
    }
    else (
        children = props.children
    )



    return (<>
        <div className={styles.content}>
            {children.map(child => <> <ContentSection> {child} </ContentSection> </>)}
        </div>
    </>)





}

export default Content
const strings = {
    // * general
    'welcome': 'Welcome to AJ Plumbing LLC'


    // * home 
    , 'homeHeader': 'Licensed & Insured'
    , 'homeintro': 'AJ Plumbing strives for the highest quality repairs and services that last while meeting customers expectations. Our goal is to hold high standards of honesty and reliable customer service. We want to complete every job as fast as possible while making it convenient for our customers. We specialize in general and emergency plumbing services for North Alabama.'


    // * gallery
    , 'galleryHeader': 'Check out some of our past projects!'

    , 'gallery': 'Gallery'
    , 'loading': 'Loading'
    , 'crawlspace': 'Crawlspace'
    , 'underground': 'Underground'
    , 'inside': 'Inside'
    , 'transformations': 'Transformations'

    // * services
    , 'services': 'Services'

    , 'servicesintro': 'Worry-Free Plumbing!'
    //proposed catagories
    // Repair
    // Maintenence
    // Installation
    // location type

    // actual catagories
    , 'buildingTypeHeader': 'Anywhere'
    , 'projectTypeHeader': 'Any Project Stage'
    , 'servicesOfferedHeader': 'We Have You Covered'

    , 'multiunitrealestate': 'Multi-unit Real Estate'
    , 'Newconstruction': 'New Construction'
    , 'Remodeling': 'Remodeling'
    , 'ResidentialService': 'Residential Service'
    , 'Commercialservice': 'Commercial Service'
    , 'Faucetinstallation': 'Faucet Installation'
    , 'Showerinstallation': 'Shower Installation'
    , 'Toiletinstallation': 'Toilet Installation'
    , 'Waterheaterinstallation': 'Water Heater Installation'
    , 'Faucetrepair': 'Faucet Repair'
    , 'Plumbingpiperepair': 'Plumbing Pipe Repair'
    , 'Toiletrepair': 'Toilet Repair'
    , 'Garbagedisposalinstallation': 'Garbage Disposal Installation'
    , 'Plumbingleakrepair': 'Plumbing Leak Repair'
    , 'Garbagedisposalrepair': 'Garbage Disposal Repair'
    , 'Outdoorplumbingsystem repair': 'Outdoor Plumbing System Repair'
    , 'Sewerrepair': 'Sewer Repair'
    , 'Showerrepair': 'Shower Repair'
    , 'Waterheaterrepair': 'Water Heater Repair'
    , 'Draincleaning': 'Drain Cleaning'
    // Services -> other
    , 'roughplumbing': 'Rough Plumbing'
    , 'roughplumbingdetails': 'details...'
    , 'bathroomremodels': 'Bathroom Remodels'
    , 'bathroomremodelsdetails': 'details...'

    // * Area
    , 'area': 'Service Area'

    , 'areaintro': 'Are you in our service area?'

    // Areas served.
    , 'Madison': 'Madison'
    , 'Athens': 'Athens'
    , 'Huntsville': 'Huntsville'
    , 'Toney': 'Toney'
    , 'Harvest': 'Harvest'
    , 'Meridianville': 'Meridianville'
    , 'Decatur': 'Decatur'
    , 'HazelGreen': 'Hazel Green'
    , 'Elkmont': 'Elkmont'

    // * Reviews
    , 'reviews': 'Reviews'
    , 'reviewsintro': 'Don\'t take it from us!'

    // * Contact
    , 'contactHeader': `Don't be shy, contact us today!`

    , 'contact': 'Contact'

    , 'contactus': 'Contact Us'

    , 'socialmedia': 'Social Media'
    , 'phone': 'Phone'
    , 'cell': 'Cell'
    , 'email': 'Email'
    , 'office': 'Office'

}
export default strings
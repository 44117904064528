import styles from './Loading.module.css'
import Localizer from '../common/localizer'

export default function Loading(props) {

    return (
        <>
            <div className={styles.LoadingContainer}>
                <h1  className={styles.LoadingBar}>{Localizer.localize('loading')}. . .</h1>
            </div>
        </>
    )
}
import { Routes, Route, Link } from 'react-router-dom';
import styles from './App.module.css';
import Contact from './components/Contact';
import Reviews from './components/Reviews';
import Services from './components/Services';
import Gallery from './components/Gallery';
import AJLogo from './assets/logos/aj_logo.png'
import Home from './components/Home';
import Navbar from './components/Navbar';
import Area from './components/Area';
import './common/global_styles.css'

function App() {
  return (
    <>
      <div className={styles.banner}>
        <Link to='/'>
          <img className={styles.bannerLogo} src={AJLogo} alt='Logo' />
        </Link>
      </div>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Gallery' element={<Gallery />} />
        <Route path='/Services' element={<Services />} />
        <Route path='/Reviews' element={<Reviews />} />
        <Route path='/Area' element={<Area />} />
        <Route path='/Contact' element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;

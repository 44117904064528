import styles from './ContentSection.module.css'
import useLazyLoad from '../hooks/LazyLoad'


function ContentSection(props) {

    const [parentRef, invisible] = useLazyLoad()


    return (
        <>
            <div ref={parentRef} className={styles.contentSection + ' ' + (invisible ? styles.visible : styles.invisible)}> {props.children} </div>
        </>
    )
}

export default ContentSection
import { NavLink } from 'react-router-dom';
import React from 'react';
import styles from './Navbar.module.css';
import Dropdown from './Dropdown';
import { stringify } from '@firebase/util';
import Hamburger from './Hamburger';
import OverlayButton from './OverlayButton'
import Localizer from '../common/localizer';


class Navbar extends React.Component {

    constructor(props) {
        super(props)
        this.shouldRenderBar = this.shouldRenderBar.bind(this)
        this.isMobile = this.isMobile.bind(this)
        this.closeHamburger = this.closeHamburger.bind(this)
        this.handleHamburgerClick = this.handleHamburgerClick.bind(this)

        this.state = {
            hamburgerOpened: false,
            staticNav: !this.isMobile()
        }
    }

    mobileWidthLimit = 550

    isMobile() {
        return window.innerWidth < this.mobileWidthLimit || window.matchMedia("(hover: none)").matches
    }

    shouldRenderBar() {
        return !this.isMobile() || this.state.hamburgerOpened
    }

    handleResize() {
        const newValue = this.shouldRenderBar()
        if (this.state.staticNav !== newValue) {
            this.setState({ staticNav: newValue, hamburgerOpened: false })
        }
    }

    handleHamburgerClick() {
        this.setState({ ...this.state, hamburgerOpened: !this.state.hamburgerOpened })
    }

    closeHamburger(event) {
        this.setState({ ...this.state, hamburgerOpened: false })
    }

    render() {
        const links =
            <>

                <div className={styles.linkContainer}>
                    {this.state.staticNav ?
                        <NavLink className={`${styles.links} ${styles.dropDownLinks}`} to='/Gallery' onClick={this.closeHamburger}>
                            { Localizer.localize('gallery')}

                        </NavLink>
                        :
                        <button className={`${styles.dropDownButtons} ${!this.state.hamburgerOpened && styles.active}`} onClick={event => event.target.classList.toggle(styles.active)} >{ Localizer.localize('gallery')}</button>
                    }
                    <Dropdown className={`${styles.dropdown}`}>
                        <>
                            <NavLink className={`${styles.links}`} to={`/Gallery?query=${encodeURIComponent(stringify({ job_location: 'inside' }))}`} onClick={this.closeHamburger}> {Localizer.localize('inside')} </NavLink>
                            <NavLink className={`${styles.links}`} to={`/Gallery?query=${encodeURIComponent(stringify({ job_location: 'crawlspace' }))}`} onClick={this.closeHamburger}> {Localizer.localize('crawlspace')} </NavLink>
                            <NavLink className={`${styles.links}`} to={`/Gallery?query=${encodeURIComponent(stringify({ job_location: 'underground' }))}`} onClick={this.closeHamburger}> {Localizer.localize('underground')} </NavLink>
                            <NavLink className={`${styles.links}`} to={`/Gallery?query=${encodeURIComponent(stringify({ collection: 'Transformations' }))}`} onClick={this.closeHamburger}> {Localizer.localize('transformations')} </NavLink>
                        </>
                    </Dropdown>
                </div>

                <div className={styles.linkContainer}>
                    <NavLink className={`${styles.links} ${this.shouldRenderBar() && styles.active}`} to='/Services' onClick={this.closeHamburger}>{ Localizer.localize('services') }</NavLink>

                    <Dropdown className={`${styles.dropdown} ${styles.active}`}>
                        <>
                        </>
                    </Dropdown>
                </div>

                {/* <div className={styles.linkContainer}>
                    <NavLink className={`${styles.links} ${this.shouldRenderBar() && styles.active}`} to='/Reviews' onClick={this.closeHamburger}>{ Localizer.localize('reviews')}</NavLink>

                    <Dropdown className={`${styles.dropdown} ${styles.active}`}>
                        <>
                        </>
                    </Dropdown>
                </div> */}

                <div className={styles.linkContainer}>
                    <NavLink className={`${styles.links} ${this.shouldRenderBar() && styles.active}`} to='/Area' onClick={this.closeHamburger}>{ Localizer.localize('area') }</NavLink>

                    <Dropdown className={`${styles.dropdown} ${styles.active}`}>
                        <>
                        </>
                    </Dropdown>
                </div>

                <div className={styles.linkContainer}>
                    <NavLink className={`${styles.links} ${this.shouldRenderBar() && styles.active}`} to='/Contact' onClick={this.closeHamburger}>{ Localizer.localize('contactus')}</NavLink>

                    <Dropdown className={`${styles.dropdown} ${styles.active}`}>
                        <>
                        </>
                    </Dropdown>
                </div>

            </>
        window.addEventListener('resize', () => this.handleResize())

        return (
            <>
                <Hamburger onClick={this.handleHamburgerClick} className={`${styles.active}`} closed={!this.shouldRenderBar()} />
                <nav className={`${styles.topNavBar} ${this.shouldRenderBar() && styles.active}`}>
                    {links}
                </nav>
                {this.state.hamburgerOpened &&
                    <OverlayButton className={`${styles.z_indexed_overlay_clickoff}`} onClick={this.closeHamburger} />
                }
            </>
        )
    }

}

export default Navbar
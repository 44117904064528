import styles from './Dropdown.module.css'
import { Component } from "react"

export default class Dropdown extends Component {
    constructor(params) {
        super(params)

        this.state = {
            className: params.className
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevParams, prevState, snapshot) {
    }

    render() {
        return (
        <>
            <div className={`${styles.dropdown} ${this.props.className}`}>
                {this.props.children}
            </div>
        </>
        )
    }
}